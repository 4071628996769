import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { SiteMetadata } from '../types/SiteMetadata';
import { CommunityFooter } from '../components/CommunityFooter';
import styled from '../styled';
import Layout, { Wrapper } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { InnerForm, SubscriptionForm } from '../components/SubscriptionForm';
import { HidableBranding as Branding } from '../components/Branding';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { SUBSCRIBER_COUNT } from '../constants';
import { Tweet } from '../components/Tweet';
import { Highlight } from '../components/Highlight';

type Data = {
  allTag: {
    nodes: {
      id: string;
      name: string;
    }[];
  };
  site: {
    siteMetadata: SiteMetadata;
  };
};

const Tweets = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  max-width: 1200px;
  margin: 0 auto 48px;
  padding-bottom: 24px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 0 ${(p) => p.theme.spacing(2)}px;
    grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

const Title = styled('div')`
  color: #e3e9f0;
  font-size: 1.8rem;
`;

const Headline = styled('h1')`
  color: #e3e9f0;
  font-size: ${(p) => p.theme.typography.h3.fontSize};
  line-height: ${(p) => p.theme.typography.h3.lineHeight};
  margin-bottom: 0;

  @media (max-width: 500px) {
    font-size: 2rem;
    font-weight: 700;
  }
`;

const Img = styled('img')`
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
`;

const Caption = styled('p')`
  font-size: 16px;
  text-align: center;
  opacity: 0.8;
`;

const Content = styled('p')`
  text-align: left;
  font-size: 1.5rem;
  margin: 2rem auto;
  color: #c9d1da;

  @media (max-width: 800px) {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  strong {
    color: #e1e5ea;
  }

  a {
    border-bottom: 2px solid white;
  }

  a:focus {
    outline: blue;
  }
`;

const Li = styled('li')`
  font-size: 1.5rem;
  color: #c9d1da;

  @media (max-width: 800px) {
    font-size: 1.3rem;
    line-height: 2rem;
  }
`;

const SubTitle = styled('p')`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #69c0ff;
`;

const Photo = styled('img')`
  border-radius: 100%;
  float: right;
  width: 125px;
  margin: 18px;
  border: 3px solid white;

  @media (max-width: 800px) {
    width: 96px;
  }
`;

export default function () {
  const data: Data = useStaticQuery(graphql`
    query {
      allTag {
        nodes {
          name
          id
        }
      }
      site {
        siteMetadata {
          title
          siteUrl
          description
          headline
          footerText
          footerUrl
        }
      }
    }
  `);
  const siteMetadata = data.site.siteMetadata;

  return (
    <div>
      <SEO
        seoTitle="Blogging For Devs: Learn to Grow Your Developer Blog"
        seoDescription="Get advice and resources for creating a strategy for your developer blog, and create content that reaches thousands without an existing audience."
        title={siteMetadata.title}
        description={siteMetadata.description}
        siteUrl={siteMetadata.siteUrl}
      />
      <ProductHuntBanner />
      <Layout>
        <Wrapper style={{ marginBottom: '48px' }}>
          <Title>
            <Branding to="/">Blogging for Devs</Branding>
            <div style={{ textAlign: 'center' }}>
              <Headline>
                Learn to grow your blog as a developer without an existing
                audience
              </Headline>
              <br />
              <Typography variant="h6" component="p" paragraph>
                Get the free 7-day email course &amp; newsletter for developers,
                makers, managers, and technical founders growing their reach
                through blogging
              </Typography>
              <br />
              <div style={{ margin: '0 auto', maxWidth: 500 }}>
                <InnerForm />
              </div>
              <Typography
                variant="body1"
                component="p"
                style={{ marginTop: '24px', opacity: 0.7 }}
              >
                Join over {SUBSCRIBER_COUNT} developers growing their blogs with
                intention
              </Typography>
              <a
                href="https://www.producthunt.com/posts/blogging-for-devs?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-blogging-for-devs"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=246818&theme=dark&period=daily"
                  alt="Blogging for Devs - Grow your blog as a developer without an existing audience | Product Hunt Embed"
                  style={{
                    width: '250px',
                    height: '54px',
                    marginTop: '48px',
                    filter: 'grayscale(0.6) contrast(1.5)',
                    opacity: 0.6
                  }}
                  width="250px"
                  height="54px"
                />
              </a>
            </div>
          </Title>
        </Wrapper>
        <SubTitle style={{ textAlign: 'center', margin: '48px auto' }}>
          Kind words from our readers
        </SubTitle>
        <Tweets>
          <div>
            <Tweet
              name="Josh Comeau"
              username="JoshWComeau"
              image="/images/josh-small.jpeg"
              url="https://twitter.com/JoshWComeau/status/1398369516745277440"
              content={
                <>
                  One of my favourite newsletters is{' '}
                  <a href="https://twitter.com/monicalent?ref_src=twsrc%5Etfw">
                    @monicalent
                  </a>
                  's Blogging for Devs. It's a goldmine of little tips and
                  tricks. This week, it's all about how to procedurally generate
                  pages that can boost your search engine rankings 💯 You can
                  join it here:{' '}
                  <a href="https://bloggingfordevs.com">bloggingfordevs.com</a>
                </>
              }
            />
            <Tweet
              name="Gergely Orosz"
              username="GergelyOrosz"
              image="/images/gergely-small.png"
              url="https://twitter.com/GergelyOrosz/status/1309527105743523841"
              content={
                <>
                  If you're writing (or planning to write) a technical blog,
                  Monica's newsletter and course are full of insights it
                  otherwise takes years to figure out.
                  <br />
                  <br />
                  Recommended, and I'm also a reader (
                  <a href="https://bloggingfordevs.com">bloggingfordevs.com</a>
                  ).
                </>
              }
            />
            <Tweet
              name="A-P Koponen"
              username="APKoponen"
              image="/images/apk-small.jpg"
              url="https://twitter.com/APKoponen/status/1340747405407494149"
              content={
                <>
                  If you're a developer and even slightly interested in
                  blogging, Monica's newsletter is pure gold!
                </>
              }
            />
          </div>
          <div>
            <Tweet
              name="Linda Ikechukwu"
              username="_MsLinda"
              image="/images/linda-small.jpg"
              url="https://twitter.com/_MsLinda/status/1269660358668685312"
              content={
                <>
                  <a href="https://twitter.com/monicalent">@monicalent</a>{' '}
                  thanks a lot for your #bloggingfordevs newsletter. It's been
                  really insightful and helpful in setting up my blog.
                  <br />
                  <br />
                  If you're a developer trying to get into blogging, I&#39;ll
                  definitely recommend checking out for her newsletter.
                </>
              }
            />
            <Tweet
              name="Rey van den Berg"
              username="ReyTheDev"
              image="/images/rey-small.jpg"
              url="https://twitter.com/ReyTheDev/status/1265907535040708610"
              content={
                <>
                  Check out{' '}
                  <a href="https://twitter.com/monicalent">@monicalent</a>'s
                  profile. She currently has a newsletter where she&#39;s
                  sharing blogging tips 🙌 they've been super insightful since I
                  started receiving them
                </>
              }
            />
            <Tweet
              name={`Shawn "Swyx" Wang`}
              username="swyx"
              image="/images/swyx.jpg"
              url="https://twitter.com/swyx/status/1289257416291258368"
              content={
                <>
                  Just want to throw a shoutout to{' '}
                  <a href="https://twitter.com/monicalent" target="_blank">
                    @monicalent
                  </a>
                  's newsletter and email course,{' '}
                  <a href="https://bloggingfordevs.com">bloggingfordevs.com</a>
                  <br />
                  <br />
                  Its a proven email newsletter on how to build an email
                  newsletter - very meta right?
                  <br />
                  <br />
                  and I'm learning a lot from how she structures these weekly
                  recs 📈
                </>
              }
            />
          </div>
          <div>
            <Tweet
              name="Joe Previte"
              username="jsjoeio"
              image="/images/joe-small.png"
              url="https://twitter.com/jsjoeio/status/1340317150456631297"
              content={
                <>
                  If one of your goals is to blog more in 2021, I highly suggest
                  signing up for the{' '}
                  <a href="https://twitter.com/bloggingfordevs" target="_blank">
                    @bloggingfordevs
                  </a>{' '}
                  newsletter by{' '}
                  <a href="https://twitter.com/monicalent" target="_blank">
                    @monicalent
                  </a>
                  .
                  <br />
                  <br />
                  Every newsletter, I learn something new or feel inspired in
                  some way.
                </>
              }
            />
            <Tweet
              name="Ashlee Boyer"
              username="AshleeMBoyer"
              image="/images/ashlee-small.jpg"
              url="https://twitter.com/AshleeMBoyer/status/1340740162737876992"
              content={
                <>
                  If you haven't subscribed for this free email course, you
                  totally should! It's super helpful and the rest of{' '}
                  <a href="https://twitter.com/monicalent" target="_blank">
                    @monicalent
                  </a>
                  's newsletter is excellent as well. Sign up!{' '}
                  <span role="img">✍️</span>
                </>
              }
            />
            <Tweet
              name="Sean Ziegler"
              username="Sean_Ziegler"
              image="/images/sean-small.jpg"
              url="https://twitter.com/Sean_Ziegler/status/1263094022907797504"
              content={
                <>
                  Just found myself checking my inbox for today’s version of
                  Blogging for Devs and wishing it had arrived - I think that’s
                  the best review I can give. Loving it so far! 👍🏻
                </>
              }
            />
            <Tweet
              name="Visaal"
              username="Visaals"
              image="/images/visaal-small.jpg"
              url="https://twitter.com/Visaals/status/1292201321852858368"
              content={
                <>
                  Huge shoutout to{' '}
                  <a href="https://twitter.com/monicalent?ref_src=twsrc%5Etfw">
                    @monicalent
                  </a>
                  's super concise email newsletter course for helping me lay
                  the groundwork for this blog. Thank you!
                </>
              }
            />
          </div>
        </Tweets>
        <Wrapper style={{ margin: '48px auto', maxWidth: '650px' }}>
          <SubTitle>
            Blogging for Devs: Free Email Course &amp; Newsletter
          </SubTitle>
          <Typography variant="h4" component="h2" style={{ fontWeight: 700 }}>
            You don't have to be Twitter-famous to grow your blog
          </Typography>
          <Content>Hi. I'm Monica 👋</Content>
          <Content style={{ fontWeight: 'bold' }}>
            And I've learned that effective blogging can change your life.
          </Content>
          <Content>It sounds kinda dramatic but it's true.</Content>
          <Content>
            Blogging has brought me: Job opportunities and consulting work.
            Speaking gigs at conferences around the world. Even the financial
            freedom to bootstrap my own company full-time.
          </Content>
          <Content>
            <strong>
              But it's not as simple as writing something and pressing
              "Publish".
            </strong>
          </Content>
          <Content>
            Going in without a strategy is not why my blog posts have been read
            over{' '}
            <em>
              <strong>one million</strong>
            </em>{' '}
            people to date.
          </Content>
          <Img
            src="/images/google-analytics.jpeg"
            alt="Google analytics screenshot"
          />
          <Caption>
            Google Analytics daily report from one of my three blogs
          </Caption>
          <Content>
            But when a few of my friends in tech asked me recently for advice
            about how to grow their own blogs, I realized that most of the
            important skills that got me to this point just aren't common for
            developers.
          </Content>
          <Content>
            <strong>
              That's why I decided to create this <em>free</em> email course 📬
            </strong>
          </Content>
          <Content>
            (Also because I've got some spare time on my hands, thanks
            coronavirus? 😭)
          </Content>
          <Content>
            I made this course in the form of a 7-day challenge to help you go
            from idea to distribution of one, excellent article.
          </Content>
          <Content>
            After the course, you'll get a twice per month email with more
            useful blogging tips, topics, and strategies.
          </Content>
          <Content>During the 7-day course, you'll learn how to:</Content>
          <ol>
            <Li>Use SEO to get discovered without an existing audience</Li>
            <Li>Figure out what content people want to read and share</Li>
            <Li>Optimize and repurpose content you've already written</Li>
            <Li>Grow an email list (without selling your soul)</Li>
            <Li>Build a personal brand around written content</Li>
          </ol>
          <Content>
            The email list is the only way to access this course (ironic, I know
            😏).
          </Content>
          <Content>So sign up below to get the course:</Content>
        </Wrapper>
        <Wrapper style={{ margin: '48px auto' }}>
          <SubscriptionForm />
        </Wrapper>
        <Wrapper style={{ margin: '48px auto', maxWidth: '650px' }}>
          <SubTitle>
            <Highlight>Meet your host 🎩</Highlight>
          </SubTitle>
          <Photo src="/images/my-face.jpg" alt="Monica Lent portrait" />
          <Content>
            My name is Monica (
            <a href="https://twitter.com/monicalent" target="_blank">
              @monicalent
            </a>
            ), and I've been writing code for over 20 years (professionally for
            ten of those).
          </Content>
          <Content>
            In the last four years, I've started three blogs on three different
            topics: travel, tech, and my own product.
          </Content>
          <Content>
            In 2019, my income from blogging enabled me to quit my job and
            bootstrap my SaaS company full-time: an analytics and attribution
            tool for affiliates called{' '}
            <a href="https://affilimate.com" target="_blank">
              Affilimate
            </a>
            .
          </Content>

          <Content>I know a ridiculous amount about blogging.</Content>

          <Content>
            (I mean really, how many times can I write the word "blogging"?)
          </Content>

          <Content>
            And I love sharing my knowledge and helping devs and people in tech
            kickstart their blogs in the right way!
          </Content>

          <Content>
            So subscribe to the Blogging for Devs email course and newsletter,
            and get the basics of what I've learned over 4 years in just 7 days.
          </Content>

          <Content>
            <img
              src="/images/signature-dark.png"
              style={{ maxWidth: '250px' }}
            />
          </Content>
        </Wrapper>
        <Wrapper style={{ margin: '48px auto 200px' }}>
          <SubscriptionForm
            description={`Join over ${SUBSCRIBER_COUNT} developers growing their blogs now:`}
          />
        </Wrapper>
        <CommunityFooter />
      </Layout>
    </div>
  );
}
